import React from 'react';
import { Link } from 'gatsby';

const ActivityBreadCrumb = (props) => {
  const { pathname } = props.location;

  const strings = pathname.split('/');

  const breadcrumbs = [];
  let breadcrumbCount = 0;
  strings.map((x) => {
    if (x !== '') {
      if (x !== 'activities') {
        breadcrumbs.push(x);
        breadcrumbCount += 1;
      }
    }
    return x;
  });

  return (
    <div className="container-fluid py-4">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/activities/">Activities</Link>
          </li>
          {breadcrumbs &&
            breadcrumbs.map((node, index) => {
              let label = '';
              if (node === 'mission-1') {
                label = 'Mission 1';
              } else if (node === 'mission-2') {
                label = 'Mission 2';
              } else if (node === 'mission-3') {
                label = 'Mission 3';
              } else if (node === 'all-aboard-the-aquabus') {
                label = 'All aboard the Aquabus';
              } else if (node === 'speedy-shower-challenge') {
                label = 'Speedy shower challenge';
              } else if (node === 'spot-the-difference') {
                label = 'Spot the difference';
              } else if (node === 'water-saving-game') {
                label = 'Water saving game';
              } else if (node === 'we-are-the-aquanauts') {
                label = 'We are the Aquanauts';
              } else if (node === 'bring-the-garden-to-life') {
                label = 'Bring the garden to life';
              } else if (node === 'drinking-water-diary') {
                label = 'Drinking water diary';
              } else if (node === 'make-a-rain-maker') {
                label = 'Make a rain maker';
              } else if (node === 'rain-cloud-in-a-jar') {
                label = 'Rain cloud in a jar';
              } else if (node === 'scavenger-hunt') {
                label = 'Scavenger hunt';
              } else if (node === 'animal-picture-search') {
                label = 'Animal picture search';
              } else if (node === 'make-your-own-river') {
                label = 'Make your own river';
              } else if (node === 'saving-water-dance') {
                label = 'Saving water dance';
              } else if (node === 'stop-the-leaking-water') {
                label = 'Stop the leaking water';
              } else if (node === 'water-saving-poster') {
                label = 'Water saving poster';
              } else if (node === 'quiz') {
                label = 'Quiz';
              }

              if (index !== breadcrumbCount - 1) {
                return (
                  <li
                    key={index}
                    className="breadcrumb-item active"
                    aria-current="page"
                  >
                    <Link to={`/activities/${node}`}>{label}</Link>
                  </li>
                );
              }
              return (
                <li
                  key={index}
                  className="breadcrumb-item active d-none d-md-block"
                  aria-current="page"
                >
                  {label}
                </li>
              );
            })}
        </ol>
      </nav>
    </div>
  );
};

export default ActivityBreadCrumb;
