import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { EmailLoader } from './shared/loaders';

const ShareYouThoughts = () => {
  const emailRegExp =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/;
  const validationSchema = yup.object().shape({
    contactEmail: yup
      .string()
      .required('Please provide your email address')
      .matches(emailRegExp, 'Please enter a valid email address'),
    contactMessage: yup.string().min(1, 'Please enter a comment'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const successMsg = {
    heading: 'Success!',
    message: "Thank you. We've got your details and will be in touch.",
  };

  const [submitting, setSubmitting] = useState(false);
  const [formSuccess, showSuccess] = useState(false);
  const [formError, showError] = useState(false);

  // const jumpRef = useRef();
  const reRef = useRef();

  const onSubmit = async (data) => {
    setSubmitting(true);
    showError(false);
    const token = await reRef.current.executeAsync();
    data.token = token;
    reRef.current.reset();

    const response = await fetch('/.netlify/functions/sendShareYouThoughts', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      body: JSON.stringify({ data }),
    });

    setTimeout(() => {
      if (response.status !== 200) {
        setSubmitting(false);
        showError(true);
        // jumpRef.current.scrollIntoView({ behavior: 'auto', block: 'center' });
      } else {
        setSubmitting(false);
        showSuccess(true);
        reset();
        // jumpRef.current.scrollIntoView({ behavior: 'auto', block: 'center' });
      }
    }, 1500);
  };

  return (
    <section className="aq-feature-bg bg-light-blue pb-9 pb-lg-16 light-blue-overlay">
      <div className="container-fluid">
        <div className="component-wrap">
          <div className="row">
            <div className="col-lg-7 mt-8 left pl-lg-8">
              <h2>Share your thoughts...</h2>
              <div className="pt-3">
                <h3>
                  We would love to know your opinion on AquaSmart, please
                  complete your details below
                </h3>
                <form className="pt-5" onSubmit={handleSubmit(onSubmit)}>
                  <fieldset>
                    <div className="form-group">
                      <label htmlFor="contactEmail" className="sr-only">
                        Email address
                      </label>
                      <input
                        type="email"
                        className={`form-control ${
                          errors.contactEmail ? 'is-invalid' : ''
                        }`}
                        placeholder="Enter email address*"
                        required=""
                        {...register('contactEmail')}
                      />
                      {errors.contactEmail && (
                        <p
                          className="text-danger font-weight-bold"
                          role="alert"
                        >
                          {errors.contactEmail?.message}
                        </p>
                      )}
                      {!errors.contactEmail && !formSuccess && <br />}
                    </div>
                    <div className="form-group">
                      <label htmlFor="contactMessage" className="sr-only">
                        Example textarea
                      </label>
                      <textarea
                        className={`form-control ${
                          errors.contactMessage ? 'is-invalid' : ''
                        }`}
                        rows="3"
                        placeholder="Type your message here..."
                        {...register('contactMessage')}
                      />
                      {errors.contactMessage && (
                        <p
                          className="text-danger font-weight-bold"
                          role="alert"
                        >
                          {errors.contactMessage?.message}
                        </p>
                      )}
                      {!errors.contactMessage && !formSuccess && <br />}
                      {formSuccess && !isDirty && (
                        <p className="form-text text-purple pb-2">
                          Great, we’ll keep you updated!
                        </p>
                      )}
                    </div>
                    <button type="submit" className="btn btn-teal mt-3">
                      Submit
                    </button>
                  </fieldset>
                </form>
                {/* ReCAPTCHA */}
                <ReCAPTCHA
                  sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                  size="invisible"
                  ref={reRef}
                />
              </div>
              {submitting && <EmailLoader />}
            </div>
            <div className="col-lg-5 image right">
              <img
                src="/images/Characters/Nero-Stand-with-Doodles.svg"
                className="w-100"
                alt="Aquanaut Nero standing"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShareYouThoughts;
